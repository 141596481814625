<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'MainGroupbuyingsList',
              }"
              >總公司團購管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>商品白名單列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-space-between">
        <div class="col-7 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">商品白名單列表</h4>
        </div>
        <div
          class="
            col-5
            d-flex
            justify-content-end
            align-items-start align-items-xl-center
          "
        >
          <b-button
            class="flex-shrink-0 mb-2 mb-xl-0 ml-2"
            variant="primary"
            @click="handleCreate"
            ><i class="fa fa-plus" aria-hidden="true"></i>上傳商品編號
          </b-button>
        </div>
      </div>

      <div
        class="
          d-flex
          flex-wrap
          mb-3
          justify-content-start justify-content-lg-end
        "
      >
        <!-- <div class="mb-2 mb-md-0">
          <b-form-select
            class="mr-2"
            style="width: 120px; height: 45px"
            v-model="keyword_type"
            :options="SEARCH_OPTIONS"
          ></b-form-select>
        </div> -->

        <div class="row-searchbar mb-2 mb-md-0 mr-md-2">
          <b-form-input
            v-model="query.keyword"
            :placeholder="PLACEHOLDER_MAP[keyword_type]"
            @keyup.enter="fetchEventProducts"
          ></b-form-input>
        </div>

        <DateSearchPicker
          class="mr-md-2 mr-0"
          v-model="query"
          :disabledEndAtDates.sync="disabledEndAtDates"
        />

        <b-button @click="fetchEventProducts"
          ><i class="fa fa-search"></i
        ></b-button>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            striped
            hover
            responsive
            :items="eventProducts"
            :fields="fields"
            :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>

            <template #cell(title)="data">
              <router-link
                :to="{
                  name: 'ProductWhiteListDetail',
                  params: {
                    event_Product_id: data.item.id,
                  },
                }"
              >
                <div>
                  {{ data.item.product && data.item.product.title }}
                </div>
                <div class="mb-1">
                  {{ data.item.product && data.item.product.sale_page_code }}
                </div>
              </router-link>
            </template>

            <template #cell(picture)="data">
              <b-img-lazy
                v-if="
                  data.item.product &&
                  data.item.product.images[0] &&
                  data.item.product.images[0].pic_url
                "
                class="thumb-image"
                :src="data.item.product.images[0].pic_url"
                alt="商品圖片"
                width="50"
                height="50"
              />
            </template>

            <template #cell(date)="data">
              <div class="mb-1">
                {{ format(new Date(data.item.start_at), "yyyy-MM-dd HH:mm") }} ~
                {{ format(new Date(data.item.end_at), "yyyy-MM-dd HH:mm") }}
              </div>
            </template>
            <template #cell(arrange)="data">
              <b-button
                class="ml-2"
                variant="primary"
                :to="{
                  name: 'ProductWhiteListDetail',
                  params: {
                    event_Product_id: data.item.id,
                  },
                }"
                >查看
              </b-button>
              <b-button
                class="ml-2"
                variant="inverse-warning"
                :to="{
                  name: 'ProductWhiteListEdit',
                  params: {
                    event_Product_id: data.item.id,
                  },
                }"
                >編輯
              </b-button>
              <b-button
                class="ml-2"
                variant="inverse-danger"
                @click="handleDelete(data.item.id)"
                >刪除
              </b-button>
            </template>
          </b-table>
        </div>
      </div>

      <div
        class="
          d-flex
          justify-content-center
          align-items-center
          flex-column flex-md-row
        "
        style="margin-top: 80px"
      >
        <b-pagination
          class="separated mb-2 mb-md-0"
          v-model="query.page"
          :total-rows="total"
          :per-page="query.per_page"
          aria-controls="groupbuyList"
          @change="handleChangePage"
        ></b-pagination>
        <div class="ml-2">
          每頁顯示數量
          <b-form-select
            v-model="query.per_page"
            :options="perPageOptions"
            style="width: 70px"
            class="ml-2"
            @change="handleChangePage"
          ></b-form-select>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { addMonths, format, subMonths } from "date-fns";
import { BTable } from "bootstrap-vue";
import { mapState } from "vuex";
import eventProductApi from "@/apis/event-product";
import { SEARCH_OPTIONS, PLACEHOLDER_MAP } from "@/utils/search";
import checkPermission from "@/mixins/checkPermission";
import DateSearchPicker from "@/components/Page/Dashboard/DateSearchPicker";

export default {
  mixins: [checkPermission],
  components: { BTable, DateSearchPicker },
  data() {
    return {
      SEARCH_OPTIONS,
      PLACEHOLDER_MAP,
      keyword_type: "product", // product or group
      isLoading: false,
      query: {
        keyword: "",
        start_at: subMonths(new Date(), 1),
        end_at: addMonths(new Date(), 1),
        page: 1,
        per_page: 10,
      },
      eventProducts: [],
      sortBy: "start_at",
      sortDesc: false,
      fields: [
        {
          key: "title",
          label: "商品名稱/編號",
        },
        {
          key: "picture",
          label: "商品圖片",
        },
        {
          key: "date",
          label: "上架時間",
        },
        {
          key: "arrange",
          label: "管理",
        },
      ],
      perPageOptions: [10, 50, 100],
      currentPage: 1,
      total: 0,
    };
  },

  created() {
    this.fetchEventProducts();
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    disabledStartAtDates() {
      if (this.query.end_at) {
        let startAt = new Date(
          this.query.end_at.getFullYear(),
          this.query.end_at.getMonth(),
          this.query.end_at.getDate()
        );
        return { from: startAt };
      }
      return {};
    },
    disabledEndAtDates() {
      if (this.query.start_at) {
        let endAt = new Date(
          this.query.start_at.getFullYear(),
          this.query.start_at.getMonth(),
          this.query.start_at.getDate() + 1
        );
        return { to: endAt };
      }
      return {};
    },
  },

  methods: {
    format,
    async fetchEventProducts() {
      try {
        this.isLoading = true;
        const params = {
          start_at: format(new Date(this.query.start_at), "yyyy-MM-dd"),
          end_at: format(new Date(this.query.end_at), "yyyy-MM-dd"),
          page: this.query.page,
          per_page: this.query.per_page,
        };
        if (this.query.keyword) params.keyword = this.query.keyword;
        const { data } = await eventProductApi.getEventProducts(params);

        this.total = data.meta.total;
        this.query.per_page = data.meta.per_page;
        this.eventProducts = data.data;
      } catch (error) {
        console.log("error", error);
      }
      this.isLoading = false;
    },

    handleCreate() {
      this.$router.push({ name: "ProductWhiteListCreate" });
    },

    handleChangePage() {
      this.$nextTick(() => {
        this.fetchEventProducts();
      });
    },

    handleSort(sortBy) {
      if (this.sortBy === sortBy) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = sortBy;
      }
      this.currentPage = 1;
      this.fetchEventProducts();
    },

    async handleDelete(id) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await eventProductApi.deleteEventProduct(id);
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "刪除成功",
                });
                await this.fetchEventProducts();
              }
            } catch (error) {
              this.$swal("失敗", error.response.data.message, "error");
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
.popover {
  max-width: 600px;
}

.row-searchbar {
  width: 35%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
</style>
